









































































































import Proxyable from '@/mixins/proxyable';
import { FormRules } from '@/utils/formRules';
import FranchisePicker from '../stafffranchisemanager/FranchisePicker.vue';

export default Proxyable.extend({
  components: { FranchisePicker },
  name: 'Verification',
  props: {
    loading: {
      type: Boolean,
    },
  },
  mixins: [FormRules],
  data() {
    return {
      parishList: [
        {
          name: 'Kingston',
          value: 'KIN',
        },
        {
          name: 'St. James',
          value: 'MBJ',
        },
      ],
      valid: false,
    };
  },
  methods: {
    goToLogin() {
      this.$router.push({ name: 'login' });
    },
    finish() {
      this.$emit('regiterAccount');
    },
    goBackToSignUp() {
      this.$emit('goBackToSignUp');
    },
  },
  mounted() {
    this.internalValue.address.pick_up_parish = 'KIN';
  },
});
