import { Address } from './address';
import { Franchise } from './franchise';
import { UserAddress } from './user_address';

export class Auth {
  id?: number; //not sure if needed
  pk?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  email2?: string;
  dob?: Date | string;
  date_of_birth?: string;
  phone?: string;
  trn?: string;
  role?: any;
  verification_file?: string;
  password1?: string;
  password2?: string;

  address?: Address;
  user_delivery_address?: UserAddress;

  franchise?: Franchise;

  constructor() {
    this.address = new Address();
    this.user_delivery_address = new UserAddress();
    this.franchise = new Franchise();
  }
}
