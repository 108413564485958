































































































































































































































































































































































































































import Vue from 'vue';
import { Auth } from '@/models/auth';
import { FormRules } from '@/utils/formRules';
import Verification from '../../components/auth/Verification.vue';
import { mapActions } from 'vuex';
import { ErrorManager } from '@/models/error';
import dateFormat from '@/utils/dateFormat';

enum Step {
  SIGNUP = 1,
  VERIFICATION = 2
}

export default Vue.extend({
  name: 'SignUp',
  components: {
    Verification
  },
  mixins: [FormRules],

  data() {
    return {
      Step,
      value: Step.SIGNUP,
      e1: 1,

      loading: false,
      valid: false,
      dialog: false,
      fromDateMenu: false,
      showPassword: false,
      success: false,
      errorMessage: '',

      fromDateVal: new Date().toISOString().slice(0, 10),

      newUser: new Auth()
    };
  },

  computed: {
    dateValue(): any {
      return this.fromDateVal;
    },

    screenWidthMobile() {
      return window.innerWidth < 600;
    },

    screenWidthTablets() {
      return window.innerWidth <= 1321;
    },

    disableBtn(): boolean {
      return (
        this.valid &&
        this.newUser.password1 == this.newUser.password2 &&
        this.newUser.password1 != '' &&
        this.newUser.password2 != ''
      );
    },

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    }
  },

  methods: {
    ...mapActions({
      register: 'auth/register'
    }),
    dateFormat,

    goToLogin() {
      this.dialog = false; //closes dialog when user clicks okay
      this.$router.push({ name: 'login' });
    },

    goToRatesSheet() {
      this.$router.push({ name: 'rates-list', params: { pagetype: 'page' } });
    },

    goBackToSignUp() {
      this.value = Step.SIGNUP;
    },

    regiterAccount() {
      this.loading = true;

      this.newUser.dob = dateFormat(this.dateValue, 'YYYY-MM-DD', false);
      this.newUser.role = 'C';

      const newUserFormData = this.ConvertToFormData(this.newUser);

      this.register(newUserFormData)
        .then((data: any) => {
          this.$router.push({ name: 'confirmationMessage' });
        })
        .catch((error: any) => {
          if (error.response.status == 400) {
            if (error.response.data.email) {
              this.errorMessage = error.response.data.email;
            } else {
              this.errorMessage = ErrorManager.extractApiError(error);
            }
          } else {
            this.errorMessage = ErrorManager.extractApiError(error);
          }
        })
        .finally(() => (this.loading = false));
    },

    ConvertToFormData(newUser: Auth) {
      const formData = new FormData();

      if (
        this.newUser.verification_file &&
        this.newUser.verification_file != null
      ) {
        formData.append('verification_file', this.newUser.verification_file);
      }
      formData.append('first_name', newUser.first_name as string);
      formData.append('last_name', newUser.last_name as string);
      formData.append('date_of_birth', newUser.dob as any);
      formData.append('trn', newUser.trn as any);
      formData.append('role', newUser.role as string);
      formData.append('phone', newUser.phone as string);
      formData.append('email', newUser.email as string);
      formData.append('franchise', newUser.franchise as any);
      formData.append('password1', newUser.password1 as string);
      formData.append('password2', newUser.password2 as string);
      formData.append(
        'address.pick_up_parish',
        newUser.address!.pick_up_parish as string
      );
      formData.append(
        'user_delivery_address.street_1',
        newUser.user_delivery_address!.street_1 as string
      );
      formData.append(
        'user_delivery_address.street_2',
        newUser.user_delivery_address!.street_2 as string
      );
      formData.append(
        'user_delivery_address.city',
        newUser.user_delivery_address!.city as string
      );
      formData.append(
        'user_delivery_address.parish',
        newUser.user_delivery_address!.parish as string
      );

      return formData;
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
});
